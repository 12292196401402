import React from 'react';
import {NavLink} from 'react-router-dom';

// Styles
import './Header.scss';

// Images
import logo from '../../assets/img/logo.png';
import iconMenu from '../../assets/img/icon-menu.png';

const Header = props => (
    <header className="header">
        <div className="container header__wrapper">
            <div className="header__logo"><NavLink to={`/`}><img src={logo} alt="Dr. Ijeoma Igwe Logo" /></NavLink></div>
            <nav className="navbar navbar--closed">
                <div className="navbar__item">
                    <NavLink className="navbar__link" onClick={toggleMenu} to={`/`} exact>Home</NavLink>
                </div>
                <div className="navbar__item">
                    <NavLink className="navbar__link" onClick={toggleMenu} to={`/about`}>Meet Dr. Igwe</NavLink>
                </div>
                {/* <div className="navbar__item">
                    <NavLink className="navbar__link" to={`/services`}>Services</NavLink>
                </div> */}
                <div className="navbar__item">
                    <NavLink className="navbar__link" onClick={toggleMenu} to={`/testimonials`}>Testmonials</NavLink>
                </div>
                <div className="navbar__item">
                    <NavLink className="navbar__link" onClick={toggleMenu} to={`/financing`}>Financing</NavLink>
                </div>
                <div className="navbar__item">
                    {/* <NavLink className="navbar__link" to={`/contact-us`}>Contact Us</NavLink> */}
                </div>
            </nav>
            <div className="navbar__hamburger" onClick={toggleMenu}><img src={iconMenu} alt=""/></div>
        </div>
    </header>
);

/**
 * Handles toggling the navigation menu
 */
const toggleMenu = () => {
    if (window.innerWidth < 768) {
        document.querySelector('.navbar').classList.toggle('navbar--closed');
    }
};

export default Header;
