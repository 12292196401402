import React from 'react';

// Styles
import './Footer.scss';

const mailchimpStyle = {
    position: 'absolute',
    left: '-5000px',
};

const Footer = (props) => (
    <footer className="footer">
        <div className="container footer__cols">
            {/* <div className="footer__logo"></div> */}
            <div className="footer__contact">
                <h4 className="footer__header">Contact Us</h4>
                <p>
                    <a
                        href="https://www.fysaesthetics.com"
                        target="_blank"
                        className="footer__link"
                        rel="noreferrer"
                    >
                        Find Your Smile Aesthetics
                    </a>
                </p>
                <p>310-918-9422</p>
                <p>info@fysaesthetics.com</p>
            </div>
            <div className="footer__location">
                <h4 className="footer__header">Our Location</h4>
                <p>5757 Wilshire Blvd STE 648</p>
                <p>Los Angeles, CA 90036</p>
            </div>
            <div className="footer__mailing-list">
                <h4 className="footer__header">Join our mailing list!</h4>
                <div className="footer__mailchimp">
                    {/* <!-- Begin Mailchimp Signup Form --> */}
                    <link
                        href="//cdn-images.mailchimp.com/embedcode/slim-10_7.css"
                        rel="stylesheet"
                        type="text/css"
                    />
                    <style type="text/css">
                        {/* #mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; } */}
                        {/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
                            We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */}
                    </style>
                    <div id="mc_embed_signup">
                        <form
                            action="https://drigwedds.us20.list-manage.com/subscribe/post?u=ad808feb20964160d14158518&amp;id=67497e17f0"
                            method="post"
                            id="mc-embedded-subscribe-form"
                            name="mc-embedded-subscribe-form"
                            className="validate"
                            target="_blank"
                            noValidate
                        >
                            <div id="mc_embed_signup_scroll">
                                <input
                                    type="email"
                                    name="EMAIL"
                                    className="email"
                                    id="mce-EMAIL"
                                    placeholder="email address"
                                    required
                                />
                                {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
                                <div style={mailchimpStyle} aria-hidden="true">
                                    <input
                                        type="text"
                                        name="b_ad808feb20964160d14158518_67497e17f0"
                                        tabIndex="-1"
                                    />
                                </div>
                                <div className="clear">
                                    <input
                                        type="submit"
                                        value="Subscribe"
                                        name="subscribe"
                                        id="mc-embedded-subscribe"
                                        className="button"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                    {/* <!--End mc_embed_signup--> */}
                </div>
            </div>
        </div>

        <div className="footer__copyright">
            <div className="container">
                <p>Copyright &copy; {new Date().getFullYear()}</p>
                <p>
                    Developed by{' '}
                    <a
                        className="footer__link"
                        href="http://www.futuredesignworks.com"
                    >
                        Future Design Works
                    </a>
                </p>
            </div>
        </div>
    </footer>
);

export default Footer;
