import React from 'react';

// Styles
import './Testimonials.scss';

const Testimonials = props => (
    <div className="testimonials">
        <div className="testimonials-hero">
            <div className="container">
                <div className="testimonials-hero__content">
                    <h1 className="testimonials-hero__header">Testimonials</h1>
                </div>
            </div>
        </div>
        <div className="container testimonials-list">
            <div className="testimonial">
                <div className="testimonial__image">
                    <img src="/images/test-e-gonzalez.jpg" alt="Dr. Ijeoma Igwe and patient Evelyn Gonzalez"/>
                </div>
                <div className="testimonial__content">
                    <h3 className="testimonial__content-writer">Evelyn Gonzales</h3>
                    <p className="testimonial__content-text">"When you finally put your fears to the side and go to the dentist. I'm so glad I found @drigwedds. She made sure I felt comfortable the whole time. My teeth feel squeaky clean I don't want to eat anything."</p>
                </div>
            </div>

            <div className="testimonial">
                <div className="testimonial__image">
                    <img src="/images/test-r-jones-jr.jpg" alt=""/>
                </div>
                <div className="testimonial__content">
                    <h3 className="testimonial__content-writer">Rodney Jones Jr.</h3>
                    <p className="testimonial__content-text">"@drigwedds is taking my smile, making it bigger and cleaner. The process has started. She does all of the stars and now I'm on board. Just wait #producer #cameraready"</p>
                </div>
            </div>
        </div>
    </div>
);

export default Testimonials;
