import React from 'react';

// Styles
import './About.scss';

const About = props => (
    <div className="about">
        <div className="about-hero">
            <div className="container">
                <div className="about-hero__content">
                    <h1 className="about-hero__header">Ijeoma Igwe D.D.S.</h1>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="about__content">
                <h2 className="about__header">Meet Dr. Igwe</h2>
                <p>Dr. Ijeoma Igwe received her B.S. degree in Biological Sciences from the University of California, Santa Barbara. She earned her Doctorate of Dental Surgery degree from UCLA School of Dentistry. Upon graduation, Dr. Igwe completed an additional year of intensive training at the Advanced Education in General Dentistry Program- Veterans Affairs Medical Center, Long Beach. A Los Angeles native, Dr. Igwe takes great pride in improving the oral health and enhancing the smiles of her clients.</p>
                <p>Dr. Igwe is a part-time faculty member at the UCLA School of Dentistry. Working with students has been very rewarding, as it gives her the opportunity to mentor and teach “the future of dentistry.” She is committed to continuing education, regularly attending lectures and seminars to remain updated on the newest advances in Dentistry.</p>
                <p>Dr. Igwe is currently a member of the American Dental Association, California Dental Association, and  Los Angeles Dental Society.</p>
            </div>

        </div>
    </div>
);

export default About;
