import React from 'react';
// import { BrowserRouter as Router } from 'react-router-dom';
// import { NavLink } from 'react-router-dom';

// Styles
import './Home.scss';

// Images
import magnifyGlass from '../../assets/img/icon-magnifying-glass.svg';
import iconteeth from '../../assets/img/icon-awesome-teeth.svg';
import iconmaterialbroken from '../../assets/img/icon-material-broken.svg';
import iconmaterialbrokengreen from '../../assets/img/icon-material-broken-green.svg';
import icontooth from '../../assets/img/icon-awesome-tooth.svg';
import iconcrown from '../../assets/img/icon-awesome-crown.svg';
import icondoctor from '../../assets/img/icon-map-doctor.svg';
import iconteechopen from '../../assets/img/icon-awesome-teeth-open.svg';

const Home = (props) => {
    return (
        <div className="home">
            <div className="home-hero">
                <div className="container">
                    <div className="home-hero__content">
                        <h1 className="home-hero__header">Dr. Ijeoma Igwe</h1>
                        <p>
                            From cosmetic to general dentistry Dr. Igwe
                            <br />
                            takes great pride in improving the oral health and
                            increasing the confidence
                            <br />
                            of her clients by providing patient-centered care
                            with an artistic touch.
                        </p>
                    </div>
                </div>
            </div>
            <div className="book-appointment">
                {/* <NavLink className="btn btn--primary" to={`/contact-us`}>Book Appointment</NavLink> */}
            </div>

            <div className="container">
                <div className="dental-services">
                    <p className="dental-services__hello">
                        We look forward to seeing you!
                    </p>
                    <h2 className="dental-services__header">
                        Dental Services We Provide
                    </h2>
                    <p className="dental-services__blurb">
                        Dr. Igwe offers a wide array of dental services and
                        while most appointments and procedures will be handled
                        in office we have a wide network of specialists if more
                        specialized work is required.
                    </p>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-6">
                            <div className="dental-services__item">
                                <div className="dental-services__icon">
                                    <img
                                        src={magnifyGlass}
                                        alt="icon of magnifying glass"
                                    />
                                </div>
                                <div className="dental-services__details">
                                    <h3 className="dental-services__item-header">
                                        Routine Exams
                                    </h3>{' '}
                                    <p className="dental-services__text">
                                        Regular dental exams are a critical part
                                        of preventive health care. During a
                                        dental exam, the dentist or hygienist
                                        will clean your teeth and check for
                                        cavities and gum disease.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-6">
                            <div className="dental-services__item">
                                <div className="dental-services__icon">
                                    <img
                                        src={iconteeth}
                                        alt="icon of teeth closed"
                                    />
                                </div>
                                <div className="dental-services__details">
                                    <h3 className="dental-services__item-header">
                                        Dental Hygiene
                                    </h3>
                                    <p className="dental-services__text">
                                        Dental hygiene and oral health are often
                                        taken for granted but are essential
                                        parts of our everyday lives.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-6">
                            <div className="dental-services__item">
                                <div className="dental-services__icon">
                                    <img
                                        src={iconmaterialbroken}
                                        alt="icon of broken teeth"
                                    />
                                </div>
                                <div className="dental-services__details">
                                    <h3 className="dental-services__item-header">
                                        Dental Fillings
                                    </h3>
                                    <p className="dental-services__text">
                                        To treat a cavity your dentist will
                                        remove the decayed portion of the tooth
                                        and then "fill" the area on the tooth
                                        where the decayed material was removed.
                                        Fillings are also used to repair cracked
                                        or broken teeth.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-6">
                            <div className="dental-services__item">
                                <div className="dental-services__icon">
                                    <img src={icontooth} alt="icon of tooth" />
                                </div>
                                <div className="dental-services__details">
                                    <h3 className="dental-services__item-header">
                                        Teeth Whitening
                                    </h3>
                                    <p className="dental-services__text">
                                        Tooth whitening lightens teeth and helps
                                        to remove stains and discoloration.
                                        Whitening is among the most popular
                                        cosmetic dental procedures.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-6">
                            <div className="dental-services__item">
                                <div className="dental-services__icon">
                                    <img src={iconcrown} alt="icon of crown" />
                                </div>
                                <div className="dental-services__details">
                                    <h3 className="dental-services__item-header">
                                        Crown/Bridge/Implants
                                    </h3>
                                    <p className="dental-services__text">
                                        A dental implant (also known as an
                                        endosseous implant or fixture) is a
                                        surgical component that interfaces with
                                        the bone of the jaw or skull to support
                                        a dental prosthesis such as a crown,
                                        bridge, denture, facial prosthesis or to
                                        act as an orthodontic anchor.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-6">
                            <div className="dental-services__item">
                                <div className="dental-services__icon">
                                    <img
                                        src={iconmaterialbrokengreen}
                                        alt="icon of broken teeth"
                                    />
                                </div>
                                <div className="dental-services__details">
                                    <h3 className="dental-services__item-header">
                                        Bonding
                                    </h3>
                                    <p className="dental-services__text">
                                        Dental bonding is a dental procedure in
                                        which a dentist applies a tooth-colored
                                        resin material (a durable plastic
                                        material) and cures it with visible,
                                        blue light. This ultimately "bonds" the
                                        material to the tooth and improves the
                                        overall appearance of teeth.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-6">
                            <div className="dental-services__item">
                                <div className="dental-services__icon">
                                    <img
                                        src={icondoctor}
                                        alt="icon of first-aid kit"
                                    />
                                </div>
                                <div className="dental-services__details">
                                    <h3 className="dental-services__item-header">
                                        Oral Surgery
                                    </h3>
                                    <p className="dental-services__text">
                                        Dental surgery is any of a number of
                                        medical procedures that involve
                                        artificially modifying dentition; in
                                        other words, surgery of the teeth, gums
                                        and jaw bones.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-6">
                            <div className="dental-services__item">
                                <div className="dental-services__icon">
                                    <img
                                        src={iconteechopen}
                                        alt="icon of magnifying glass"
                                    />
                                </div>
                                <div className="dental-services__details">
                                    <h3 className="dental-services__item-header">
                                        Dentures/Partials
                                    </h3>
                                    <p className="dental-services__text">
                                        Dentures are prosthetic devices
                                        constructed to replace missing teeth,
                                        and are supported by the surrounding
                                        soft and hard tissues of the oral
                                        cavity.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* end dental-services */}

            <div className="instagram">
                <div className="container">
                    <div className="instagram__items">
                        <div className="instagram__item">
                            <p className="instagram__header">
                                <svg
                                    role="img"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="instagram__icon"
                                >
                                    <title>Instagram</title>
                                    <path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z" />
                                </svg>
                                <a
                                    href="https://www.instagram.com/dr.ijeomaigwe/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    @DR.IJEOMAIGWE
                                </a>
                            </p>
                            <p className="instagram__blurb">
                                Follow <em>Dr. Ijeoma Igwe</em> <br />
                                on Instagram for up to date news, <br />
                                information, and more.
                            </p>
                        </div>
                        <div className="instagram__item">
                            <p className="instagram__header">
                                <svg
                                    role="img"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="instagram__icon"
                                >
                                    <title>Instagram</title>
                                    <path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z" />
                                </svg>
                                <a
                                    href="https://www.instagram.com/fysaesthetics/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    @FYSAESTHETICS
                                </a>
                            </p>
                            <p className="instagram__blurb">
                                Follow the team at <br />
                                <em>Find Your Smile Aesthetics</em> <br />
                                on Instagram!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
