import React from 'react';

// Styles
import './Financing.scss';

// Images
import hasAccount from '../../assets/img/has-account.png';
import noAccount from '../../assets/img/no-account.png';

const Financing = props => (
    <div className="financing">
        <div className="financing-hero">
            <div className="container">
                <div className="financing-hero__content">
                    <h1 className="financing-hero__header">Use CareCredit to pay<br />Dr. Igwe online</h1>
                    <p>CareCredit offers easy financing with the convenience of payments on the go.</p>
                </div>
            </div>
        </div>
        <div className="financing__bar">&nbsp;</div>
        <div className="container">
            <div className="p-grid financing__care-credit">
                <div className="p-col-12">
                    <img src="/images/care-credit-logo.png" alt="Care Credit Logo" className="cc-logo" />
                </div>
                <div className="p-col-12">
                    <p className="financing__main-text">Dr. Igwe now offers an easy way to finance and pay for your dental services. With CareCredit you can make easy online payments, before or after your scheduled visit.</p>
                </div>
            </div>
            <div className="p-grid">
                <div className="p-col-12 p-md-6">
                    <img src={hasAccount} alt="Generic person outline in green with a checkmark in front." className="cc-image"/>
                    <p>Already have an account with Dr. Igwe?</p>
                    <p>Click below to pay your balance.</p>

                    <a href="https://www.carecredit.com/Pay/BWJ288/&sitecode=B3CPLAdToolkitPMPCard" className="btn" target="_blank" rel="noopener noreferrer">Pay Account</a>
                </div>
                <div className="p-col-12 p-md-6">
                    <img src={noAccount} alt="Generic person outline in black." className="cc-image"/>
                    <p>Want to apply for CareCredit? It's easy.</p>
                    <p>Click below to get started.</p>

                    <a href="https://www.carecredit.com/apply/confirm.html?encm=VjdSawdlVDFRbVYyBjJdNA40BWNRM1FuVDFQYl0xBTM&sitecode=B3CALAdToolkitANCardDental" className="btn" target="_blank" rel="noopener noreferrer">Create Account</a>
                </div>
            </div>
        </div>
    </div>
);

export default Financing;
