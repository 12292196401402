import React from 'react';

// Style
import './Error404.scss';

const Error404 = props => (
    <div className="error-404">
        <div className="error-404-hero">
            <div className="container">
                <h1 className="error-404-hero__header">Dr. Ijeoma Igwe - 404 Error Page</h1>
            </div>
        </div>
        <div className="container">
            <h1 className="error-404__header">Oops...</h1>
            <p>This page does not exist.</p>
            <p>If you believe there is an error with the website please email development@drigwedds.com</p>
        </div>
    </div>
);

export default Error404;
