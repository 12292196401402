// Dependencies
import React from 'react';
import { Route, Switch } from 'react-router-dom';

// Components
import Content from './Layout/Content/Content';
import Home from './Components/Home/Home'
import About from './Components/About/About';
import Error404 from './Components/Error404/Error404';
import Financing from './Components/Financing/Financing';
import Testimonials from './Components/Testimonials/Testimonials';
// import Fibonacci from './Components/Fibonacci/Fibonacci';

const AppRoutes = (props) => (
    <Content>
        <Switch>

            <Route path="/about" component={About} exact />
            <Route path="/financing" component={Financing} exact />
            <Route path="/testimonials" component={Testimonials} exact />
            <Route path="/" component={Home} exact />
            <Route component={Error404} />
        </Switch>
    </Content>
);

export default AppRoutes;
